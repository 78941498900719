import React, {useEffect, useState} from 'react';
import "../css/pagescardnavbar.css";
import "../css/pagescard.css";
import PagesNavbar from "../components/pagescardnavbar";


function Kaszni() {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch('https://www.skoda110rcoupe.hu/api/pictures');
                const data = await response.json();
                console.log('Fetched image paths:', data);
                setImages(data);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
        fetchImages();
    }, []);

    return (
        <div className='kaszni-kartyak'>
            <div className='interior-navbar'>
                <div className='interior-nav'>
                    <PagesNavbar/>
                </div>
            </div>
            <div className="container-fluid">
                <div className="pages-card">
                    {images.map((image, index) => (
                        <div key={index}>
                            <div className='card'>
                                <a href={`${image.path}/${image.name}`} target="_blank" rel="noopener noreferrer">
                                    <img className="card-img-top" src={`${image.path}/${image.name}`}
                                         alt={`Kép ${index + 1}`} style={{width: "15rem", height: "13rem"}}/></a>
                                <div className="card-body"><h5 className='card-title'>{}</h5> </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
        ;
}

export default Kaszni;