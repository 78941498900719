import React from 'react';
import '../css/szelepFedelMove.css';
import '../css/fooldal.css';
import MyNavbar from "../components/navbar";


function Fooldal() {

    return (
        <div className="container-fluid fooldal">

            <div className="row row-cols-lg-2 row-cols-md-1 row-cols-sm-1 fooldal-body">
                <div className="fooldal-szelepfedelimg">
                    <img className="img-fluid fooldal-szelepfedel" src={require('../kepek/szelepfedel.png')}
                         alt="szelepfedel"/>
                    <MyNavbar/>
                </div>

                <div className='fooldal-skodaimg'>
                    <img
                        className="img-fluid skoda-img"
                        src={require('../kepek/800x764_hatterkep_szakadt.png')}
                        alt="skoda110r" style={{width: "100vh"}}
                    />
                </div>
                <div className='fooldal-navbar'>

                </div>
            </div>
            <div className='row row-cols-1 row-cols-lg-2 row-cols-md-1 row-cols-sm-1'>

            </div>
        </div>
    )
        ;
}

export default Fooldal
