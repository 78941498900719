import React from 'react';
import "../css/carosuel.css";


function Carousel() {
    return (
        <div className="container-fluid">
            <div id="demo" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="3"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="4"></button>
                </div>

                <div className="row col-sm-6 col-md-7 col-lg-4">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={require("../kepek/carousel/krisztian/1.jpg")} className="d-block img-fluid"
                                 alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={require("../kepek/carousel/krisztian/2.jpg")} className="d-block img-fluid"
                                 alt="..."/>
                        </div>
                        <div className="carousel-item">

                            <img src={require("../kepek/carousel/krisztian/3.jpg")} className="d-block img-fluid"
                                 alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={require("../kepek/carousel/krisztian/4.jpg")} className="d-block img-fluid"
                                 alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={require("../kepek/carousel/krisztian/5.jpg")} className="d-block img-fluid"
                                 alt="..."/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Carousel;