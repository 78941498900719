import React, {useEffect, useState} from 'react';
import "../css/pagescard.css";
import "../css/pagescardnavbar.css";
import PagesNavbar from "../components/pagescardnavbar";


function Movies() {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch('https://www.skoda110rcoupe.hu/api/movies');
                const data = await response.json();
                console.log('Fetched video data:', data);
                setVideos(data); // 'data' kulcs elérése
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        };

        fetchVideos();
    }, []);

    return (
        <div className="movies-card">
            <div className='interior-navbar'>
                <div className='interior-nav'>
                    <PagesNavbar/>
                </div>
            </div>
            <div className="container-fluid">
                <main className="pages-card">
                    {videos.map((video, index) => (
                        <div key={index}>
                            <div className="card">
                                <a href={`${video.path}/${video.name}`} target="_blank" rel="noopener noreferrer">
                                    <video className="card-img-top"
                                           style={{width: "15rem", height: "13rem", borderRadius: "25px"}} controls>
                                        <source src={`${video.path}/${video.name}`} type='video/mp4'/>
                                        Your browser does not support the video tag.
                                    </video>
                                </a>
                                <div className="card-body"><h5 className='card-title'></h5></div>
                            </div>
                        </div>
                    ))}
                </main>
            </div>
        </div>
    );
}

export default Movies;
