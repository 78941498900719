import React from 'react';
import {Link} from 'react-router-dom';
import '../css/fenykepek.css';
import "../css/pagescardnavbar.css";
import Carousel from '../components/carousel';
import MyNavbar from "../components/navbar";


function Fenykepek() {

    return (

        <div className="container-fluid fenykepek-body">

            <div className='row fenykepek-navbar'>

                <div className='fenykepek-nav'>
                    <MyNavbar/>
                </div>
            </div>

            <div className='row row-cols-2 row-cols-lg-6 row-cols-md-3 row-cols-sm-3 fenykepek-card'>

                <div className="col card">
                    <div className='div-img'>
                        <img className="img-fluid card-img-top"
                             src={require("../kepek/400X300kaszni.png")} alt="kaszni"/>
                    </div>
                    <div className='button'>
                        <Link to="/kaszni" className="link-button kaszni">Kaszni</Link>
                    </div>
                </div>

                <div className="col card">
                    <div className='div-img'>
                        <img className="img-fluid card-img-top"
                             src={require("../kepek/400X225muszerfal_tabla.png")} alt="interior"/>
                    </div>
                    <div className='button'>
                        <Link to="/interior" className="link-button interior">Kárpit</Link>
                    </div>
                </div>

                <div className="col card">
                    <div className='div-img'>
                        <img className="img-fluid card-img-top"
                             src={require("../kepek/400X300futomu01.png")}
                             alt="futomu"/>
                    </div>
                    <div className='button'>
                        <Link to="/suspension" className="link-button suspension">Futómű</Link>
                    </div>
                </div>

                <div className="col card">
                    <div className='div-img'>
                        <img className="img-fluid card-img-top" src={require("../kepek/film.png")} alt="hamarosan"/>
                    </div>
                    <div className='button'>
                        <Link to="/movies" className="link-button movies">Videok</Link>
                    </div>
                </div>

                <div className="col card">
                    <div className='div-img'>
                        <img className="img-fluid card-img-top" src={require("../kepek/400X367skoda.png")}
                             alt="car"/>
                    </div>
                    <div className='button'>
                        <Link to="/car" className="link-button car">Skoda 110 R Coupe</Link>
                    </div>
                </div>
            </div>
            <Carousel/>
        </div>
    );
}


export default Fenykepek;
