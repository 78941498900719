// navbar.js
import '../css/navbar.css';
import React from 'react';
import {Link} from 'react-router-dom';

function MyNavbar() {
    return (
        <div className='container-fluid navbar'>
            <div className='navbar-div'>
                <ul className='nav-ul'>

                    <li className="nav-item">
                        <Link className="nav-link " to="/">Kezdőlap</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/fenykepek">Fényképek / Videók</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/leiras">Leírás / Kapcsolat</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
        ;
}

export default MyNavbar;
